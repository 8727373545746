import React from 'react'
import { Container,Col,Row } from 'react-bootstrap';
import training from '../../Assets/workshop.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const Training= () => {
  return (
    <div className="page-content">
    <Container>
      <h1 className='page-heading'>Training & Work Intership</h1>
      <img src={training} alt="" className="middle-image" />
      <Row className="mb-4">
          <Col xs={12} md={10} className="mx-auto">
            <p className='text'>Empower with essential technical skills for application design, development, and operations. Training is typically designed to teach new skills or knowledge to employees, while internships are designed to provide hands-on experience to students or recent graduates. Training is a process of learning new skills or knowledge, usually in a formal setting such as a classroom or training center.</p>
            <p className='text2'><br/>An internship, on the other hand, is a period of on-the-job training where a person works in a particular field or industry to gain practical experience and develop skills. Internships are often completed as part of a college or university program and may be paid or unpaid.</p>
          </Col>
        </Row>
      </Container>
      <Container> 

<h2 className='chooseus-heading'>Why Choose Us</h2>
  <div className="card2-container">
    <div className="card2">
      <img src={costImage} alt="Cost Effective" />
      <h3>Cost Effective</h3>
    </div>
    <div className="card2">
      <img src={easyImage} alt="Ease of Use" />
      <h3>Ease of Use</h3>
    </div>
    <div className="card2">
      <img src={teamImage} alt="Team Work" />
      <h3>Team Work</h3>
    </div>
    <div className="card2">
      <img src={securityImage} alt="Security" />
      <h3>Security</h3>
    </div>
  </div>
</Container>
    </div>
  )
}

export default Training
