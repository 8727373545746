import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import digital from '../../Assets/Digital marketing.png';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import './Pages.css';

const DigitalMarketing = () => {
  return (
    <div className="page-content">
      <Container>
            <h1 className="page-heading digital">Digital Marketing</h1>
            <img src={digital} alt="Digital Marketing" className="middle-image img-fluid" />

        {/* Text Paragraphs */}
        <Row className="mb-4">
        <Col xs={10} md={20} className="mx-auto">  {/* Reduce width and center text */}
            <p className="text">
              Amplify your brand's reach and impact with strategic Digital Marketing. Digital marketing refers to the use of digital channels, platforms, and technologies to promote products, services, brands, or ideas to target audiences. It encompasses a wide range of strategies and tactics designed to reach consumers where they spend much of their time—online.
            </p>
            <p className="text2">
              A semi-fictional representation of an ideal customer based on market research and real data. Personas help businesses tailor their digital marketing strategies to specific audiences.
            </p>
          </Col>
        </Row>
        </Container>
        <Container> {/* Another Container for the "Why Choose Us" section */}
        <h2 className='chooseus-heading'>Why Choose Us</h2>
        <div className="card2-container">
          <div className="card2">
            <img src={costImage} alt="Cost Effective" />
            <h3>Cost Effective</h3>
          </div>
          <div className="card2">
            <img src={easyImage} alt="Ease of Use" />
            <h3>Ease of Use</h3>
          </div>
          <div className="card2">
            <img src={teamImage} alt="Team Work" />
            <h3>Team Work</h3>
          </div>
          <div className="card2">
            <img src={securityImage} alt="Security" />
            <h3>Security</h3>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DigitalMarketing;
