import React, {useEffect}from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // import AOS styles
// import Typewriter from 'typewriter-effect';
import './About.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import handshakeImage from '../../Assets/abouthero.png';
import image1 from '../../Assets/image (1).png';
import image2 from '../../Assets/image (2).png';
import image3 from '../../Assets/image (3).png';
import image4 from '../../Assets/image (4).png';
import image5 from '../../Assets/image (5).png';
import image6 from '../../Assets/image (6).png';
import image7 from '../../Assets/image (7).png';
import visionImage from '../../Assets/visionlogo.png';
import missionImage from '../../Assets/missionlogo.png';

const About = () => {
useEffect(() => {
    AOS.init({
      duration: 1200,  // Customize animation duration
      easing: 'ease-in-out', // Customize easing function
      once: true,  // Only run the animation once
    });
  }, []);  
  const images = [image1, image2, image3, image4, image5, image6, image7]; 
  const titles = ["Innovation", "Goal", "Teamwork", "Commitment", "Integrity", "Customers", "Integrity"];

  return (
    <>
      <div className="about-container mt-7">
        <img 
          src={handshakeImage}
          alt="About Us"
          className=" mb-4 w-100 custom-image "
        />
        <h1 className="h1text ">About Us</h1>
        <div className="containertext">
          <h2 className="h2text">'Transforming ideas into results with creativity and precision.'
            {/* <Typewriter
        options={{
          strings: ['Transforming ideas into results with creativity and precision.'],
          autoStart: false,
          loop: false, // Optionally, set this to false to stop the animation after one cycle
          delay: 100, // Controls the typing speed (in ms)
          deleteSpeed: 0, 
        }}
        onInit={(typewriter) => {
          typewriter
            .typeString('Transforming ideas into results with creativity and precision.')
            .start();
        }}
      /> */}
    </h2>
  
          <p className="about-details">
          "Nexonica, based in Nashik, India, is a premier IT company specializing in cutting-edge technology. As a trusted IT solution provider, we empower businesses to automate operations using the latest advancements in IoT, Full Stack Development, Cloud, Robotics, AI/ML, and more. Our expertise spans technologies like C#.NET, Java Spring Boot, gRPC, Google Guice, AWS, Python, UI/UX, React JS, Android, Flutter, Raspberry Pi, and Embedded Systems. In addition to application development, integration, and management services, we are passionate about fostering talent through internships, industrial training, seminars, and workshops. At Nexonica, we are committed to innovation and excellence, helping businesses and individuals thrive in the digital age" Nexonica is an Indian IT company that provides information technology, consulting, and business process services. We are situated at the world’s best place called Nashik. We are an IT solution provider to all of them who want to automate their existing work with the latest technologies, application development and integration, and application management services.
          The company has been promoted by most of the veteran professionals. We endow with total IT solution under one crown. Emerging Technologies is one of the Pioneer IT companies in Nashik, who work on the latest technologies like IoT, Raspberry with everything Smarting to Automation. We also work on Android, Angular, PHP, Java, Firebase, Arduino. Our expertise lies in the ability to create innovative systems application software to meet business-specific needs. We provide extraordinary solutions to automate your daily business needs with ease. The company is focused on new opportunities in the IT and computing industry, automating its existing work at a nascent stage in Nashik and all over Maharashtra. Very soon we will be providing the solution throughout India and all over the world. The company deals in e-commerce, mobile applications, cloud computing, digital transformation, data analytics, enterprise application integration, and enterprise resource planning, with more than 100 active clients across the country. We are also a part of training for industrial and educational institutes, serving seminars across the institutes to get aware of the latest technologies and trends. We also provide internships for various students as well as short-term training in the latest trends in development and IoT-related fields.          </p>
        </div>
      </div>

      <div className="values text-center">
        <h1 className="vour">Our Core Values</h1>
      </div>
      
      <div className="slider-wrapper">
      <div className="image-slider mt-4">
        {images.map((image, index) => (
          <div key={index} className="slider-image">
            <img src={image} alt={`Slide ${index + 1}`} className="img-fluid" />
            <h5 className="image-title">{titles[index]}</h5> {/* Title below the image */}
          </div>
        ))}
      </div>
    </div>

      {/* Vision and Mission Section */}
      <div className="vision-mission-wrapper" >
        {/* Vision Section */}
        <div data-aos="flip-down">
          <h2 className="vision-heading">Our Vision</h2>
          <div className="vision-container">
            <div className="vision-content">
              <p>
              Our vision at Nexonica is to be the catalyst for transformative change in the business landscape.Vision logo
We envision a future where every business,regardless of its size or industry, harnesses the power of emerging technologies to achieve unparalleled success, efficiency, and sustainability. and excellence in every sector we touch.


              </p>
              <img src={visionImage} alt="Vision" className="vision-image" />
            </div>
          </div>
        </div>

        {/* Mission Section */}
        <div>
          <h2 className="mission-heading">Our Mission</h2>
          <div className="mission-container" data-aos="flip-up">
            <div className="mission-content">
              <p>
              We are committed to innovation, collaboration, and quality, providing adaptable solutions designed to evolve with our clients’ changing needs.mission logo
              Our success is measured by the positive impact our solutions have on the growth and sustainability of the businesses we partner with.Empowering businesses through cutting-edge software and automation solutions.              </p>
              <img src={missionImage} alt="Mission" className="mission-image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
