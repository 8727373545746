import React from 'react';
import './career.css'; // Ensure this import path is correct

const GoogleForm = () => {
  return (
    <div className="google-form-container">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLScoei0EadeypUu6FISy3LSEw_Bzx9Xbk0-SaiGCQ4kggbzHpQ/viewform?embedded=true"
        title="Google Form"
        className="responsive-iframe"
      ></iframe>
    </div>
  );
};

export default GoogleForm;
