import React from 'react'
import { Container,Col,Row } from 'react-bootstrap';
import iot from '../../Assets/IOT.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const IOT = () => {
  return (
    <div className="page-content">
      
      <Container>
      <h1 className='page-heading iot'>IOT(Internet Of Things)</h1>
      <img src={iot} alt="" className="middle-image"  />
      <Row className="mb-4">
      <Col xs={10} md={20} className="mx-auto">
      <p className='text'><br/>IoT devices can automate tasks, improve efficiency, and provide convenience. The Internet of Things (IoT) refers to a network of physical objects—often called "things"—that are embedded with sensors, software, and other technologies to connect and exchange data with other devices and systems over the internet.
       </p>
       <p className='text2'><br/>These "things" can range from simple household devices like refrigerators and thermostats to complex industrial machines. physical objects that are connected to the internet, equipped with sensors, processors, and communication hardware.

      </p>
      </Col>
      </Row>
      </Container>
      <Container> 

      <h2 className='chooseus-heading'>Why Choose Us</h2>
        <div className="card2-container">
          <div className="card2">
            <img src={costImage} alt="Cost Effective" />
            <h3>Cost Effective</h3>
          </div>
          <div className="card2">
            <img src={easyImage} alt="Ease of Use" />
            <h3>Ease of Use</h3>
          </div>
          <div className="card2">
            <img src={teamImage} alt="Team Work" />
            <h3>Team Work</h3>
          </div>
          <div className="card2">
            <img src={securityImage} alt="Security" />
            <h3>Security</h3>
          </div>
        </div>
      </Container>
    </div>
   
  )
}

export default IOT
