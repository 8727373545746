import React from 'react';      
import './career.css';
import careernwave from '../../Assets/Wave.png'; 
import GoogleForm from './careerform';
import model from '../../Assets/model.png';
import square from '../../Assets/Square.png';
import { Container, Row, Col } from 'react-bootstrap';

const Career = () => {
  return (
    <div className="career-wrapper">
      <Container fluid className="p-4">
        <Row className="align-items-center text-center">
          {/* Left Section with Model Image */}
          <Col xs={4} className="d-flex justify-content-center align-items-center">
            <img 
              src={model} 
              alt="Model" 
              className="img-fluid model-img" 
            />
          </Col>

          {/* Center Section with Text */}
          <Col xs={4} className="text-center">
            <h1 className="career-title">Career With Nexonica</h1>
            <p className="career-description">
              "Excited to contribute and grow with Nexonica, where innovation meets excellence."
            </p>
          </Col>

          {/* Right Section with Square Design */}
          <Col xs={4} className="d-flex justify-content-center align-items-center">
            <img 
              src={square} 
              alt="Square Design" 
              className="img-fluid square-img" 
            />
          </Col>
        </Row>
      </Container>

      {/* Wave Design */}
      <div className="wave-container">
        <img 
          src={careernwave} 
          alt="Career Wave Design" 
          className="img-fluid careerwave-img w-100" 
        />
      </div>

      {/* Google Form */}
      <Container>
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <GoogleForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Career;
