import React, {useEffect}from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // import AOS styles

import { useNavigate } from 'react-router-dom';
import './Home.css';
import { Container, Row, Col } from 'react-bootstrap';
import wave from '../../Assets/Wave.png'
import js from '../../Assets/JS.gif';
import square from '../../Assets/Square.png';
import about from '../../Assets/About.png';
import more from '../../Assets/moreabout.png';
import mobileapp from '../../Assets/mobile-app 1.png';
import devlopment from '../../Assets/development 1.png';
import marketing from '../../Assets/marketing 1.png';
import layer from '../../Assets/layers 1.png';
import traineeship from '../../Assets/traineeship 1.png';
import search from '../../Assets/search (1) 1.png';
import app from '../../Assets/Appcourse.png';
import angular from '../../Assets/Angularcourse.png';
import digitalcourse from '../../Assets/DMcourse.png';
import react from '../../Assets/reactcourse.png';
import node from '../../Assets/nodecouurse.png';
import viewmorecourse from '../../Assets/viewmore.png';
import blurwave from '../../Assets/blurwave.png';
import Card from '../Services/Card';
import Nlogo from '../../Assets/Logo.png';
import icon from '../../Assets/icon.png';


const officeHours = [
  { day: "Monday", time: "10:00 AM - 6:00 PM" },
  { day: "Tuesday", time: "10:00 AM - 6:00 PM" },
  { day: "Wednesday", time: "10:00 AM - 6:00 PM" },
  { day: "Thursday", time: "10:00 AM - 6:00 PM" },
  { day: "Friday", time: "10:00 AM - 6:00 PM" },
  { day: "Saturday", time: "10:00 AM - 6:00 PM" },
  { day: "Sunday", time: "Closed" },
];

const currentDay = new Date().toLocaleString("en-US", { weekday: "long" });

const Home = () => {
  const navigate = useNavigate();

  const handleMoreAboutClick = () => {
    navigate("/aboutus");
    setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
  };

  useEffect(() => {
    AOS.init({
      duration: 1200,  // Customize animation duration
      easing: 'ease-in-out', // Customize easing function
      once: true,  // Only run the animation once
    });
  }, []);
  

  
  const handleViewMoreCourseClick = () => {
    navigate("/courses");
  };

  return (
    <div>
    <Container className="mt-5">
            <Row className="align-items-center">
                {/* First Column: Text and Image */}
                <Col md={6} className="text-center text-md-start mb-4">
                    <h2 className="responsive-text mb-4">
                        Transform your growth journey
                        <br />
                        with our despoke IT solutions.
                    </h2>
                    <img
                        src={js}
                        alt="Logo 1"
                        className="img-fluid img-size"
                    />
                </Col>

                {/* Second Column: Placeholder */}
                <Col md={6}></Col>
            </Row>

            {/* Fixed Side Image */}
            <img
                src={square}
                alt="Logo 2"
                className=" fixed-square-img"
            />
        </Container>

      <div className="wave-container">
        <img src={wave} alt="Wave Design" className="img-fluid wave2-img" />
      </div>

      <Container>
        <Row className="about-row align-items-center">
          <Col md={7} data-aos="fade-right">
            <div className="text-content">
              <p className="about-text">
              "Nexonica, based in Nashik, India, is a premier IT company specializing in cutting-edge technology. As a trusted IT solution provider, we empower businesses to automate operations using the latest advancements in IoT, Full Stack Development, Cloud, Robotics, AI/ML, and more. Our expertise spans technologies like C#.NET, Java Spring Boot, gRPC, Google Guice, AWS, Python, UI/UX, React JS, Android, Flutter, Raspberry Pi, and Embedded Systems. In addition to application development, integration, and management services, we are passionate about fostering talent through internships, industrial training, seminars, and workshops....
              </p>
              <img
                src={more}
                alt="More About Nexonica"
                className="more-image"
                onClick={handleMoreAboutClick}
                style={{ cursor: "pointer" }}
              />
            </div>
          </Col>
          <Col md={5} className="d-flex justify-content-end" data-aos="zoom-in">
      <img src={about} alt="About Nexonica" className="about-image" />
    </Col>
        </Row>
        <div className="third-row">
        <h2 className="services-head">Our Services</h2>
        <Row className="services1-grid g-4">
          <Col md={4} className="d-flex justify-content-center mb-4" data-aos="zoom-in">
            <Card circleImage={mobileapp} title="Mobile Apps" description="Your Vision, Our future. Build your dream app and experience with us." />
          </Col>
          <Col md={4} className="d-flex justify-content-center mb-4"data-aos="zoom-in">
            <Card circleImage={devlopment} title="Website Development" description="Everything you need. We fulfill the expectation and build more websites for various purposes." />
          </Col>
          <Col md={4} className="d-flex justify-content-center mb-4"data-aos="zoom-in">
            <Card circleImage={layer} title="Software Development" description="Innovative Software Development, transforming ideas into powerful digital solutions." />
          </Col>
          <Col md={4} className="d-flex justify-content-center mb-4"data-aos="zoom-in">
            <Card circleImage={search} title="Research & Development" description="Fuel innovation with our Research & Development." />
          </Col>
          <Col md={4} className="d-flex justify-content-center mb-4"data-aos="zoom-in">
            <Card circleImage={traineeship} title="Training and Work Internship" description="Empower with essential technical skills for application design, development, and operations." />
          </Col>
          <Col md={4} className="d-flex justify-content-center mb-4"data-aos="zoom-in">
            <Card circleImage={marketing} title="Digital Marketing" description="Amplify your brand's reach with strategic Digital Marketing." />
          </Col>
        </Row>
      </div>
            
        <h3 className="course-head">Our Courses</h3>

        <div className="top-row" data-aos="zoom-in">
          <img
            src={app}
            alt="App Development Course"
            className="course-image"
          
          />
          <img
            src={angular}
            alt="Angular Course"
            className="course-image"
           
          />
          <img
            src={digitalcourse}
            alt="Digital Marketing Course"
            className="course-image"
     
          />
        </div>

        <div className="bottom-row"data-aos="zoom-in">
          <img
            src={react}
            alt="React Course"
            className="course-image"
      
          />
          <img
            src={node}
            alt="Node.js Course"
            className="course-image"
        
          />
        </div>

        <img
          src={viewmorecourse}
          alt="View More Courses"
          className="viewmore-img"
          onClick={handleViewMoreCourseClick}
          style={{ cursor: "pointer" }}
        />
      </Container>
        
      <div className="blurwave-wrapper">
        <img src={blurwave} alt="Blur Wave" className="blurwave-img w-100" />
      </div>
      <Container className="contact-container">
  <h4 className="Contact-head">Contact Us</h4>
  <div className="contact-content row">
    {/* Left Section */}
    <div className="contact-content-left col-12 col-md-4">
      <img
        src={Nlogo}
        alt="Nexonica Logo"
        className="Nlogo-img d-block "
        data-aos="zoom-in"
      />
      <ul className="contact-list mt-4">
        <h3>Address</h3>
        <p className="address">
          F5, Ashtvinayak Park C, near Croma, College Rd, Thatte Nagar, Nashik, Maharashtra 422005
        </p>
        <h3>Call Us</h3>
        <p className="callus">+91 7775022203</p>
        <h3>Email</h3>
        <p className="email">systems@nexonica.com</p>
       
      </ul>
    </div>

    {/* Center Section - Business Hours */}
    <div className="contact-content-center col-12 col-md-4 mt-4 mt-md-0">
      <h4 className="hour-head">Business Hours</h4>
      <div className="table-responsive">
        <table className="table mx-auto text-center " style={{ border: "none", borderCollapse: "collapse" }}>
          <tbody>
            {officeHours.map(({ day, time }) => (
              <tr key={day}>
                <td>
                  <span
                    className={day === 'Sunday' ? 'sunday' : (currentDay === day ? 'hour-day' : '')}
                  >
                    {day}
                  </span>
                </td>
                <td className="colon">:</td>
                <td>
                  <span
                    className={day === 'Sunday' ? 'sunday' : (currentDay === day ? 'hour-time' : '')}
                  >
                    {time}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <img src={icon} alt="Follow Icon" className="icon1-img" data-aos="zoom-in"/>
    </div>

    {/* Right Section - Google Maps */}
    <div className="contact-content-right col-12 col-md-4 mt-4 mt-md-0" data-aos="zoom-in">
      <iframe
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&q=Nexonica%20Syatems%20Pvt%20Ltd&zoom=10&maptype=roadmap"
        width="100%"
        height="300"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        title="Nexonica Location"
      ></iframe>
    </div>
  </div>
</Container>


    </div>
  );
};

export default Home;
