import React from 'react';
import { Container,Col,Row } from 'react-bootstrap';
import mobileapps from '../../Assets/Mobile apps (1).gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const mobileapp = () => {
  
  return (
    <div className="page-content">
    <Container>
      <h1 className='page-heading' >Mobile Application</h1>
      <img src={mobileapps} alt="" className="middle-image" />
      <Row className="mb-4">
      <Col xs={12} md={10} className="mx-auto">
      <p className='text'>Your Vision, Our Future. Build your dream app and experience reality
       with us. A mobile application, or mobile app, is a software program designed to run on mobile devices like smartphones and tablets. These apps are typically available through app stores such as Apple's App Store,Google Play,or Microsoft Store, depending on the operating system (iOS, Android, Windows).
       </p>
       <p className='text2'>The main goal of any mobile application should be to provide real and meaningful value to users. This involves solving problems, simplifying tasks, or providing quality entertainment. A valuable app will be one that users use regularly and that improves their quality of life in some way.
      </p>
      </Col>
        </Row>
      </Container>
      <Container> 

<h2 className='chooseus-heading'>Why Choose Us</h2>
  <div className="card2-container">
    <div className="card2">
      <img src={costImage} alt="Cost Effective" />
      <h3>Cost Effective</h3>
    </div>
    <div className="card2">
      <img src={easyImage} alt="Ease of Use" />
      <h3>Ease of Use</h3>
    </div>
    <div className="card2">
      <img src={teamImage} alt="Team Work" />
      <h3>Team Work</h3>
    </div>
    <div className="card2">
      <img src={securityImage} alt="Security" />
      <h3>Security</h3>
    </div>
  </div>
</Container>
    </div>
  )
}

export default mobileapp
