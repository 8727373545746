import React, {useEffect}from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card'; 
import './Services.css';
import mobileapp from '../../Assets/mobile-app 1.png';
import development from '../../Assets/development 1.png';
import marketing from '../../Assets/marketing 1.png';
import iot from '../../Assets/iot 1.png';
import layer from '../../Assets/layers 1.png';
import ux from '../../Assets/ux.png';
import traineeship from '../../Assets/traineeship 1.png';
import artificial from '../../Assets/artificial-intelligence.png';
import search from '../../Assets/search (1) 1.png';
import square2 from '../../Assets/Square.png';
import wave2 from '../../Assets/Wave.png';


const Services = () => {
  useEffect(() => {
      AOS.init({
        duration: 1200,  // Customize animation duration
        easing: 'ease-in-out', // Customize easing function
        once: true,  // Only run the animation once
      });
    }, []);
  return (
    <div>
      <img src={wave2} alt="Wave" className=" img-fluid mb-4 w-100 wave-img"  />
    <div className="services">
     <img src={square2} alt="Square" className="Square2-img " />
      <div className="container">
    <h4 className="titletext" data-aos="zoom-in">Services We Offer</h4>
    <p className="para-text" data-aos="zoom-out">
      Building Digital world as a solution for your real-time
      requirements helps you to control the system through our
      services.
    </p>
  </div>

  <h5 className="services-heading">Our Services</h5>
      {/* Bootstrap container added here */}
      <div className="container">
        <div className="row">
          {/* Service Cards */}
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4"  data-aos="fade-right" data-aos-delay="300">
            <a href="/mobileapp" style={{ textDecoration: 'none' }}>
              <Card 
                circleImage={mobileapp}
                title="Mobile Apps"
                description="Your Vision, Our future. Build your dream app and experience with us. Mobile applications should provide real and meaningful value to users."
              />
            </a>
          </div>
          
          {/* Other service cards */}
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4" data-aos="fade-up" data-aos-delay="100">
            <a href="/Webdevelopment" style={{ textDecoration: 'none' }}>
              <Card
                circleImage={development}
                title="Website Development"
                description="Everything you need. We fulfill expectations and build websites for various purposes. It involves creating and maintaining websites."
              />
            </a>
          </div>
          
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4" data-aos="fade-left" data-aos-delay="300">
            <a href="/Softwaredevelopment" style={{ textDecoration: 'none' }}>
              <Card
                circleImage={layer}
                title="Software Development"
                description="Innovative Software Development, transforming ideas into powerful digital solutions. Software is the set of instructions or program."
              />
            </a>
          </div>
          
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4"  data-aos="fade-right" data-aos-delay="300">
            <a href="/Researchdevelopment" style={{ textDecoration: 'none' }}>
              <Card
                circleImage={search}
                title="Research & Development"
                description="Fuel innovation with our Research & Development, pioneering solutions for a brighter future. "
              />
            </a>
          </div>
          
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4 trainingcard " data-aos="fade-up" data-aos-delay="100">
            <a href="/Training" style={{ textDecoration: 'none' }}>
              <Card
                circleImage={traineeship}
                title="Training and Work Internship"
                description="Empower with essential technical skills for application design, development, and operations."
              />
            </a>
          </div>
          
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4"data-aos="fade-left" data-aos-delay="300">
            <a href="/Digitalmarketing" style={{ textDecoration: 'none' }}>
              <Card
                circleImage={marketing}
                title="Digital Marketing"
                description="Amplify your brand's reach and impact with strategic Digital Marketing. It encompasses a wide range of strategies."
              />
            </a>
          </div>
          
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4"data-aos="fade-right" data-aos-delay="300">
            <a href="/IOT" style={{ textDecoration: 'none' }}>
              <Card
                circleImage={iot}
                title="IOT (Internet Of Things)"
                description="IoT devices can automate tasks, improve efficiency, and provide convenience."
              />
            </a>
          </div>
          
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4" data-aos="fade-up" data-aos-delay="100">
            <a href="/Uiux" style={{ textDecoration: 'none' }}>
              <Card
                circleImage={ux}
                title="UI/UX Design"
                description="Crafting the visual elements of a product, including layout, color schemes, and interactive elements."
              />
            </a>
          </div>
          
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4"data-aos="fade-left" data-aos-delay="300">
            <a href="/AI" style={{ textDecoration: 'none' }}>
              <Card
                circleImage={artificial}
                title="Artificial Intelligence (AI)"
                description="Artificial intelligence makes it possible for machines to learn from experience."
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Services;
