import React from 'react'
import { Container,Col,Row } from 'react-bootstrap';
import software from '../../Assets/software development.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const softwaredevelopment = () => {
  return (
    <div className="page-content">
      <Container>
      <h1 className='page-heading software'>Software Development</h1>
      <img src={software} alt="" className="middle-image"  />
      <Row className="mb-4">
      <Col xs={10} md={20} className="mx-auto">
      <p className='text'>Innovative Software Development, transforming ideas into powerful digital solutions. Software development refers to a set of computer science activities that are dedicated to the process of creating, designing, deploying, and supporting software. It involves a variety of tasks and stages that collectively bring a software product from concept to reality.Software development is the process of designing, creating, testing, and maintaining software applications.</p>
       <p className='text2'>Software itself is the set of instructions or programs that tell a computer what to do. It is independent of hardware and makes computers programmable.
      </p>
      </Col>
      </Row>
      </Container>
      <Container> 

<h2 className='chooseus-heading'>Why Choose Us</h2>
  <div className="card2-container">
    <div className="card2">
      <img src={costImage} alt="Cost Effective" />
      <h3>Cost Effective</h3>
    </div>
    <div className="card2">
      <img src={easyImage} alt="Ease of Use" />
      <h3>Ease of Use</h3>
    </div>
    <div className="card2">
      <img src={teamImage} alt="Team Work" />
      <h3>Team Work</h3>
    </div>
    <div className="card2">
      <img src={securityImage} alt="Security" />
      <h3>Security</h3>
    </div>
  </div>
</Container>
    </div>
  )
}

export default softwaredevelopment
