import React from 'react'
import { Container,Col,Row } from 'react-bootstrap';
import web from '../../Assets/Website designer.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const Webdevelopment = () => {
  return (
    <div className="page-content" >
      <Container>
      <h1 className='page-heading'>Web Development</h1>
      <img src={web} alt="" className="middle-image"  />
      <Row className="mb-4">
      <Col xs={10} md={20} className="mx-auto">
      <p className='text'>Everything you need. We fulfill the expectation and build the more websites for various purposes. Website development involves creating and maintaining websites. It encompasses a variety of tasks, including web design, web content creation, client-side/server-side scripting, and network security configuration.Web development is the process of creating and maintaining websites. </p>
       <p className='text2'>It encompasses a variety of tasks, including web design, coding, and content management. Web development is closely related to the job of designing the features and functionality of apps (web design). The term development is usually reserved for the actual construction of these things (that is to say, the programming of sites). 
      </p>
      </Col>
      </Row>
      </Container>
      <Container> 

<h2 className='chooseus-heading'>Why Choose Us</h2>
  <div className="card2-container">
    <div className="card2">
      <img src={costImage} alt="Cost Effective" />
      <h3>Cost Effective</h3>
    </div>
    <div className="card2">
      <img src={easyImage} alt="Ease of Use" />
      <h3>Ease of Use</h3>
    </div>
    <div className="card2">
      <img src={teamImage} alt="Team Work" />
      <h3>Team Work</h3>
    </div>
    <div className="card2">
      <img src={securityImage} alt="Security" />
      <h3>Security</h3>
    </div>
  </div>
</Container>
    </div>
  )
}

export default Webdevelopment
