import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css"; // Your custom CSS for additional styling
import Logo from "../../Assets/Logo.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation(); // Tracks the current route

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavLinkClick = () => {
    setIsMenuOpen(false); // Close the menu on link click
  };

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="Logo" className="d-inline-block align-top nlogo1" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" onClick={handleNavLinkClick}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/aboutus" className="nav-link" onClick={handleNavLinkClick}>
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/services" className="nav-link" onClick={handleNavLinkClick}>
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/learning" className="nav-link" onClick={handleNavLinkClick}>
                  Learning
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/media" className="nav-link" onClick={handleNavLinkClick}>
                  Media
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/career" className="nav-link" onClick={handleNavLinkClick}>
                  Career
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contactus" className="contact-navlink" onClick={handleNavLinkClick}>
                  Contact Us
                </NavLink>
              </li>
            </ul>
            <div className="d-flex ms-3">
              <div
                className="fas fa-search me-3"
                onClick={() => alert("Search clicked!")}
              ></div>
              <div
                className="fas fa-shopping-cart"
                onClick={() => alert("Cart clicked!")}
              ></div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
