import React, {useEffect}from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import './learning.css';
import Mobilecard from '../../Assets/mobilecard.png';
import AIcard from '../../Assets/AIcard.png';
import Digitalcard from '../../Assets/Digitalcard.png';
import Computernetcard from '../../Assets/Computernetcard.png';
import webcard from '../../Assets/Webcard.png';
import softwarecard from '../../Assets/Softwarecard.png';
import Reactcard from '../../Assets/reactcard.png';
import Angularcard from '../../Assets/Angularcard.png';
import Nodecard from '../../Assets/Nodecard.png';
import UIcard from '../../Assets/UIcard.png';
import Trainingcard from '../../Assets/trainingcard.png';
import Pythoncard from '../../Assets/pythoncard.png';
import LearnCard from './learncard';
import learncircle from '../../Assets/learncircle.png';
import learnwave from '../../Assets/learnwave.png';

const Learning = () => {
  useEffect(() => {
      AOS.init({
        duration: 1200,  // Customize animation duration
        easing: 'ease-in-out', // Customize easing function
        once: true,  // Only run the animation once
      });
    }, []);  
  return (
    <div>
       <div className="learning-container">
    <img src={learnwave} className="mediabg" alt="learnwave" />
    <img src={learncircle} className="mediahero" alt="learncircle" />
  </div>
      <p className='learn-head' data-aos="flip-down">
        "Start learning today and build a solid foundation in IT with our hands-on courses, designed to make you industry-ready."
      </p>
      <h1 className='learn-course'>Our Courses</h1>

      {/* Bootstrap Grid for Cards */}
      <div className="container">
        <div className="row">
          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={Mobilecard}
              title="App Development" 
              description="Embark on a journey to master app development. Learn to create engaging and functional mobile applications using industry-standard tools and frameworks. Elevate your expertise."
            />
          </div>

          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={AIcard}
              title="Artificial Intelligence" 
              description="Delve into the world of Artificial Intelligence (AI). Explore machine learning, neural networks, and natural language processing. Understand the impact of AI on various industries. Unlock the potential of AI."
            />
          </div>

          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={Digitalcard}
              title="Digital Marketing" 
              description="Unleash the power of digital marketing. Learn strategies to boost online presence, optimize SEO, and manage social media campaigns effectively.Digital marketing is an essential part of modern business strategy. Digital marketing is an essential part of modern business strategy. "
            />
          </div>
        </div>

        {/* Second Row of Cards */}
        <div className="row">
          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={Computernetcard}
              title="Computer Networking" 
              description="Dive into the world of computer networking. Explore concepts such as protocols, IP addressing, routing, and elevate your understanding of computer networking.  A computer network is a collection of interconnected devices, such as computers, servers, routers, and switches."
            />
          </div>

          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={webcard}
              title="Website Development" 
              description="Master the fundamentals of website development in this comprehensive course. Learn responsive design techniques for creating dynamic and visually appealing websites."
            />
          </div>

          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={softwarecard}
              title="Software Development" 
              description="Immerse yourself in the world of software development. Learn best practices in code architecture and understand the software development lifecycle. Software development is the process of designing, coding, testing, and maintaining applications, frameworks, or other software components.  
"
            />
          </div>
        </div>

        {/* Third Row of Cards */}
        <div className="row">
          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={Reactcard}
              title="React JS" 
              description="Unleash the power of React JS in this foundational course. Explore component-based architecture, state management, and JSX syntax. Learn to build dynamic, high-performance web applications."
            />
          </div>

          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={Angularcard}
              title="Angular JS" 
              description="Unlock the power of dynamic web development with our comprehensive 'Introduction to AngularJS' course. Learn to build single-page applications using JavaScript.AngularJS is a JavaScript-based open-source front-end web framework developed by Google."
            />
          </div>

          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={Nodecard}
              title="Node JS" 
              description="Dive into the fundamentals of Node.js. Explore server-side JavaScript, asynchronous programming, and building scalable web applications. Learn to leverage the power of Node.Node.js is an open-source, cross-platform runtime environment that allows developers to execute JavaScript code."
            />
          </div>
        </div>

        {/* Fourth Row of Cards */}
        <div className="row">
          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={UIcard}
              title="UI/UX Design" 
              description="Craft the visual elements of a product, including layout, color schemes, and interactive elements. UI/UX Design refers to the process of designing user interfaces (UI) and user experiences (UX)."
            />
          </div>

          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={Trainingcard}
              title="Training and Work Internship" 
              description="Embark on a transformative internship! Gain hands-on experience, mentorship, and networking opportunities. Tailored for students and recent grads, our program ensures career-ready skills and a pathway to success."
            />
          </div>

          <div className="col-md-4"data-aos="zoom-in">
            <LearnCard
              image={Pythoncard}
              title="Python" 
              description="Learn Python, a versatile programming language used in various fields like web development, data analysis, and more. Start building your coding skills with practical examples.Python is a high-level, interpreted, and general-purpose programming language known for its simplicity and readability."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Learning;
