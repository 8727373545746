import React from 'react';
import { Container,Col } from 'react-bootstrap'; // Import Bootstrap Container
import ai from '../../Assets/artificial intelligence.gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import './Pages.css';

const Ai = () => {
  return (
    <div className="page-content">
      <Container> {/* Bootstrap Container for responsiveness */}
        <h1 className='page-heading'>Artificial Intelligence (AI)</h1>
        <img src={ai} alt="AI Illustration" className="middle-image" />
        <Col xs={10} md={20} className="mx-auto"> 
        <p className='text'>
          These tasks include learning, reasoning, problem-solving, perception, understanding natural language, and even creativity. AI encompasses a broad range of techniques and technologies, from simple rule-based systems to complex deep learning models. Artificial intelligence makes it possible for machines to learn from experience, adjust to new inputs, and perform human-like tasks.
        </p>
        <p className='text2'>
          <br />
          Artificial Intelligence (AI) is a branch of computer science that focuses on creating systems capable of performing tasks that typically require human intelligence. AI systems are designed and trained to perform a specific task or a narrow set of tasks.
        </p>
        </Col>
      </Container>
      <Container> {/* Another Container for the "Why Choose Us" section */}
        <h2 className='chooseus-heading'>Why Choose Us</h2>
        <div className="card2-container">
          <div className="card2">
            <img src={costImage} alt="Cost Effective" />
            <h3>Cost Effective</h3>
          </div>
          <div className="card2">
            <img src={easyImage} alt="Ease of Use" />
            <h3>Ease of Use</h3>
          </div>
          <div className="card2">
            <img src={teamImage} alt="Team Work" />
            <h3>Team Work</h3>
          </div>
          <div className="card2">
            <img src={securityImage} alt="Security" />
            <h3>Security</h3>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Ai;
