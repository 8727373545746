import React, {useEffect}from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // import AOS styles

import './contact.css';
import contacthero from '../../Assets/contacthead.png';
import gps from '../../Assets/gps 1.png';
import phn from '../../Assets/telephone-call 2.png';
import mail from '../../Assets/email (1) 2.png';

const ContactUs = () => {
  useEffect(() => {
      AOS.init({
        duration: 1200,  // Customize animation duration
        easing: 'ease-in-out', // Customize easing function
        once: true,  // Only run the animation once
      });
    }, []);
  return (
    <div>
      {/* Full-width image below the navbar */}
      <img src={contacthero} alt="Contact hero" className="contacthero-image img-fluid w-100" />

      <div className="container"> {/* Bootstrap container */}
        <div className='contact-container text-center'>
          <h1 className='contact-top'>
            Get in touch with us now and our representative will help you soon.
          </h1>
        </div>

        <div className='form-container'>
          <div className='row'>
            {/* Form Section */}
            <div className='col-md-6'data-aos="zoom-in">
              <form name='form2'>
                <input className="form-control my-2" placeholder="Enter First Name" style={{ fontSize: '14px', padding: '8px' }} />
                <div className='form-row row'>
                  <div className='form-group col-md-6'>
                    <input className="form-control my-2" placeholder="Mobile No" style={{ fontSize: '14px', padding: '8px' }} />
                  </div>
                  <div className='form-group col-md-6'>
                    <input className="form-control my-2" placeholder="Enter email" style={{ fontSize: '14px', padding: '8px' }} />
                  </div>
                </div>
                <textarea className="form-control my-2" placeholder="Message" rows="3" style={{ fontSize: '14px', padding: '8px' }} />
                <div className="text-center">
      <button
        className="btn btn-primary mt-2"
        style={{ padding: '5px 16px', backgroundColor: '#0A466d', fontWeight:'bold' }}
      >
        Send Message
      </button>
    </div>
              </form>
            </div>

            {/* Contact Info Section */}
            <div className='col-md-6 d-flex justify-content-center align-items-center '> 
  <ul className="contactus list-unstyled mt-8">
    <li className="d-flex align-items-center my-2 scroll-animation delay-1">
      <img src={mail} alt="Mail Icon" className="contactus-icon mail-icon me-2" />
      <p className="contact1-text paragraph-spacing">systems@nexonica.com</p>
    </li>
    <li className="d-flex align-items-center my-2  scroll-animation delay-2">
      <img src={phn} alt="Phone Icon" className="contactus-icon me-2 " />
      <p className="contact1-text paragraph-spacing">+91 7775022203</p>
    </li>
    <li className="d-flex align-items-center my-2 scroll-animation delay-3">
      <img src={gps} alt="Address Icon" className="contactus-icon address-icon me-2 " />
      <p className="contact1-text paragraph-spacing">
        F5, Ashtvinayak Park C, near Croma, behind HDFC bank College Rd, Thatte Nagar, Nashik, Maharashtra 422005
      </p>
    </li>
  </ul>
</div>


          </div>
        </div>

        {/* Location Section with Map */}
        <div className='location-container my-10'>
        <iframe width="100%" height="450" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&q=Nexonica%20Syatems%20Pvt%20Ltd&zoom=10&maptype=roadmap"></iframe>

        </div>
      </div>
    </div>
  );
}

export default ContactUs;
