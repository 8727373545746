import React from 'react'
import { Container,Col,Row } from 'react-bootstrap';
import research from '../../Assets/Research development (1).gif';
import costImage from '../../Assets/cost.png';
import easyImage from '../../Assets/easy-to-use (1).png';
import teamImage from '../../Assets/Team.png';
import securityImage from '../../Assets/security 1.png';
import'./Pages.css';

const Researchdevelopment = () => {
  return (
    <div className="page-content">
    <Container>
      <h1 className='page-heading research'>Research & Development</h1>
      <img src={research} alt="" className="middle-image" />
      <Row className="mb-4">
      <Col xs={10} md={20} className="mx-auto">
      <p className='text'>Fuel innovation with our Research & Development, pioneering solutions for a brighter future. Research and Development (R&D) is a critical aspect of innovation and growth for organizations, industries, and economies. It involves the systematic work conducted to increase knowledge, and the use of this knowledge to create new applications, products, processes, or services.</p>
       <p className='text2'><br/>Research and development (R&D) is a valuable tool for growing and improving your business. R&D involves researching your market and your customer needs and developing new and improved products and services to fit these needs.
      </p>
      </Col>
      </Row>
      </Container>
      <Container> 

<h2 className='chooseus-heading'>Why Choose Us</h2>
  <div className="card2-container">
    <div className="card2">
      <img src={costImage} alt="Cost Effective" />
      <h3>Cost Effective</h3>
    </div>
    <div className="card2">
      <img src={easyImage} alt="Ease of Use" />
      <h3>Ease of Use</h3>
    </div>
    <div className="card2">
      <img src={teamImage} alt="Team Work" />
      <h3>Team Work</h3>
    </div>
    <div className="card2">
      <img src={securityImage} alt="Security" />
      <h3>Security</h3>
    </div>
  </div>
</Container>
    </div>
  )
}

export default Researchdevelopment
